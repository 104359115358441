import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LoanDetailsTable from './loan-details-table';
import ProgressBar from './progress-bar';
import { withRouter, Redirect } from 'react-router-dom';
import ErrorPage from './error-page';
import { useDataService } from '../hooks/use-data-service';
import {
  useGlobalStateContext,
  withGlobalStateProvider
} from '../context/state-context';
import '../scss/main-page.scss';

function MainPage({ match }) {
  const { error } = useGlobalStateContext();
  const username = match.params.username;
  const { customerDetails, customerId, isLoading, isRedirect } = useDataService(
    username
  );

  if (isRedirect) {
    return (
      <div data-testid='redirect'>
        <Redirect to='/connexform' />
      </div>
    );
  }

  if (error) {
    return (
      <>
        <ErrorPage message={error} />
      </>
    );
  }

  return (
    <>
      {isLoading ? (
        <ProgressBar className='progress-bar' />
      ) : (
        <LoanDetailsTable customer={customerDetails} id={customerId} />
      )}
    </>
  );
}

export default withRouter(withGlobalStateProvider(MainPage));

MainPage.propTypes = {
  match: PropTypes.object
};
