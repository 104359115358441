import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableHead, TableRow, TableCell } from '@material-ui/core';
import Row from './row';

export default function LoanDetailsTable({ customer, id }) {
  const { borrowerName, operationName, address } = customer;
  const loanDetails = customer.deferredPaymentLoanDashboardList;
  const DATE_OPTIONS = { year: 'numeric', month: 'short', day: 'numeric' };

  return (
    <div className='app-content'>
      <div className='loan-table'>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Loan Year</TableCell>
              <TableCell>Borrower Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Loan Status</TableCell>
              <TableCell>Principal Balance</TableCell>
              <TableCell>Interest Balance</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {loanDetails &&
            loanDetails.map(details => (
              <Row
                key={details.loanID}
                details={details}
                name={borrowerName}
                operation={operationName}
                address={address}
                id={id}
              />
            ))}
        </Table>
      </div>

      <div className='footnotes'>
        <p className='pdf-problem-note'>
          (If there is a problem viewing PDF, <br /> check to make sure pop-ups
          are enabled.)
        </p>
        <p className='interest-note'>
          *Interest calculated as of{' '}
          {new Date().toLocaleDateString('en-us', DATE_OPTIONS)}
        </p>
        <p className='balance-detail-note'>
          *Balance information does not reflect any scheduled or in process
          payments.
        </p>
        <p className='balance-overview-note'>
          Balance information is based on the most recent transactions presently
          posted and may not reflect all seed, crop protection, or other
          transactions. We reserve the right to make changes that reflect actual
          transaction amounts.
        </p>
      </div>
    </div>
  );
}

LoanDetailsTable.propTypes = {
  customer: PropTypes.object,
  id: PropTypes.string
};
