import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PrintCouponButton from './print-coupon-button';
import {
  withGlobalStateProvider,
  useGlobalStateDispatch
} from '../context/state-context';
import * as DataService from '../services/data-service';
import { ProgressCircle } from '../components/progress-circle';

function PrintCoupon({ details, name, operation, address, id }) {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useGlobalStateDispatch();
  const request = {
    businessPartnerID: id,
    accountID: details.loanID,
    operation,
    name,
    address,
    year: details.year,
    programCode: details.programCode,
    accountType: details.year + details.programCode
  };

  async function getPdf() {
    setLoading(true);
    let pdf;
    try {
      pdf = await DataService.getCouponPdf(request);
    } catch (error) {
      setLoading(false);
      dispatch({
        type: 'setError',
        value: 'There was an error generating the PDF'
      });
    }

    if (pdf) {
      setLoading(false);
      const url = window.URL.createObjectURL(
        new Blob([pdf], { type: 'application/pdf' })
      );
      window.open(url);
    }
  }

  return isLoading ? (
    <ProgressCircle className='progress-circle' />
  ) : (
    <PrintCouponButton onClick={getPdf} />
  );
}

export default withGlobalStateProvider(PrintCoupon);

PrintCoupon.propTypes = {
  details: PropTypes.object,
  name: PropTypes.string,
  operation: PropTypes.string,
  address: PropTypes.object,
  id: PropTypes.string
};
