import React, { createContext, useContext, useReducer } from 'react';

const StateContext = createContext();
const GlobalDispatchContext = createContext();

function stateReducer(state, action) {
  switch (action.type) {
    case 'setError': {
      return { error: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function withGlobalStateProvider(Component) {
  return props => {
    const [state, dispatch] = useReducer(stateReducer, { error: false });
    return (
      <StateContext.Provider value={state}>
        <GlobalDispatchContext.Provider value={dispatch}>
          <Component {...props} />
        </GlobalDispatchContext.Provider>
      </StateContext.Provider>
    );
  };
}

function useGlobalStateContext() {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error(
      'useGlobalStateContext must be used within an GlobalStateProvider'
    );
  }
  return context;
}

function useGlobalStateDispatch() {
  const context = useContext(GlobalDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useGlobalStateDispatch must be used within an errorProvider'
    );
  }
  return context;
}

export {
  useGlobalStateContext,
  useGlobalStateDispatch,
  withGlobalStateProvider
};
