import React from 'react';
import { withStyles, makeStyles, lighten } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: lighten('#337321', 0.5)
  },
  barColorPrimary: {
    backgroundColor: '#337321'
  }
})(LinearProgress);

const useStyles = makeStyles(theme => ({
  progress: {
    marginTop: theme.spacing(4)
  }
}));

export default function ProgressBar() {
  const classes = useStyles();
  return <ColorLinearProgress className={classes.progress} />;
}
