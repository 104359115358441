import React, { useEffect, useState } from 'react';
import {
  FaUserSlash,
  FaExclamationCircle,
  FaHistory,
  FaFileAlt,
  FaRegCheckCircle
} from 'react-icons/fa';

export function useNoteIconMapper(status) {
  const { securedLoanStatus, agreementStatus, balanceSheetStatus } = status;
  const [securedLoanNote, setSecuredLoanNote] = useState();
  const [agreementNote, setAgreementNote] = useState();
  const [balanceSheetNote, setBalanceSheetNote] = useState();
  const [securedLoanIcon, setSecuredLoanIcon] = useState();
  const [agreementIcon, setAgreementIcon] = useState();
  const [balanceSheetIcon, setBalanceSheetIcon] = useState();

  useEffect(() => {
    setSecuredLoanDetails(
      securedLoanStatus,
      setSecuredLoanIcon,
      setSecuredLoanNote
    );
    setBalanceSheetDetails(
      balanceSheetStatus,
      setBalanceSheetIcon,
      setBalanceSheetNote
    );
    setAgreementDetails(agreementStatus, setAgreementIcon, setAgreementNote);
  }, []);
  return {
    agreementIcon,
    securedLoanIcon,
    balanceSheetIcon,
    securedLoanNote,
    balanceSheetNote,
    agreementNote
  };
}

function setSecuredLoanDetails(
  securedLoan,
  setSecuredLoanIcon,
  setSecuredLoanNote
) {
  switch (securedLoan) {
    case 'Not Applicable':
      setSecuredLoanNote('Your next step: No Action Necessary');
      setSecuredLoanIcon(
        <FaUserSlash className='icon' data-testid='slash-user-icon' />
      );
      break;
    case 'Collateral Requested':
      setSecuredLoanNote(
        'Your next step: Collateral being requested for loan approval'
      );
      setSecuredLoanIcon(
        <FaExclamationCircle
          className='fa-inverse-icon'
          data-testid='exclamation-circle-icon'
        />
      );
      break;
    case 'In Progress':
      setSecuredLoanNote(
        'Your next step: Complete the forms requested from PHI FS'
      );
      setSecuredLoanIcon(
        <FaExclamationCircle
          className='fa-inverse-icon'
          data-testid='exclamation-circle-icon'
        />
      );
      break;
    case 'Collateral Secured':
      setSecuredLoanNote('Collateral documents have been processed by PHI FS');
      setSecuredLoanIcon(
        <FaRegCheckCircle className='icon' data-testid='completed-icon' />
      );
      break;
  }
}

function setBalanceSheetDetails(
  balanceSheet,
  setBalanceSheetIcon,
  setBalanceSheetNote
) {
  switch (balanceSheet) {
    case 'Not Applicable':
      setBalanceSheetNote('Your next step: No Action Necessary');
      setBalanceSheetIcon(
        <FaUserSlash className='icon' data-testid='slash-user-icon' />
      );
      break;
    case 'Needs Balance Sheet':
      setBalanceSheetNote(
        'Your next step: Submit balance sheet from lender to PHI FS'
      );
      setBalanceSheetIcon(
        <FaExclamationCircle
          className='fa-inverse-icon'
          data-testid='exclamation-circle-icon'
        />
      );
      break;
    case 'Returned To Customer':
    case 'Sent To Customer':
      setBalanceSheetNote('Your next step: Submit balance sheet to PHI FS');
      setBalanceSheetIcon(
        <FaExclamationCircle
          className='fa-inverse-icon'
          data-testid='exclamation-circle-icon'
        />
      );
      break;
    case 'In Progress':
      setBalanceSheetNote('Being processed by PHI FS');
      setBalanceSheetIcon(
        <FaHistory className='icon' data-testid='history-icon' />
      );
      break;
    case 'Balance Sheet Reviewed':
      setBalanceSheetNote('Balance Sheet has been processed by PHI FS');
      setBalanceSheetIcon(
        <FaFileAlt className='icon' data-testid='balance-sheet-icon' />
      );
      break;
  }
}

function setAgreementDetails(agreement, setAgreementIcon, setAgreementNote) {
  switch (agreement) {
    case 'Not Applicable':
      setAgreementNote('Your next step: No Action Necessary');
      setAgreementIcon(
        <FaUserSlash className='icon' data-testid='slash-user-icon' />
      );
      break;
    case 'Needs Agreement':
      setAgreementNote(
        'Your next step: Complete Agreement online or print blank DP Loan Agreement'
      );
      setAgreementIcon(
        <FaExclamationCircle
          className='fa-inverse-icon'
          data-testid='exclamation-circle-icon'
        />
      );
      break;
    case 'Returned To Customer':
      setAgreementNote(
        'Your next step: Complete Agreement and return to PHI FS'
      );
      setAgreementIcon(
        <FaExclamationCircle
          className='fa-inverse-icon'
          data-testid='exclamation-circle-icon'
        />
      );
      break;
    case 'In Progress':
      setAgreementNote('Being processed by PHI FS');
      setAgreementIcon(
        <FaHistory className='icon' data-testid='history-icon' />
      );
      break;
    case 'Completed':
      setAgreementNote('Agreement has been processed by PHI FS');
      setAgreementIcon(
        <FaRegCheckCircle className='icon' data-testid='completed-icon' />
      );
      break;
  }
}
