import React from 'react';
import { TextField, Button, Container, Grid } from '@material-ui/core';
import '../scss/connex-form.scss';

export default function ConnexForm() {
  return (
    <Container className='app-content'>
      <form action='/paymentcoupon/' method='post'>
        <h2>Payment Coupon Request</h2>
        <Grid container className='grid-container'>
          <Grid className='grid-item'>
            <TextField
              id='username'
              name='username'
              label='Username'
              margin='normal'
            />
          </Grid>
          <Grid className='grid-item'>
            <TextField id='fisid' name='fisid' label='FisId' margin='normal' />
          </Grid>
          <Grid className='grid-item'>
            <TextField id='date' name='date' label='Date' margin='normal' />
          </Grid>
          <Grid className='grid-item'>
            <TextField id='mac' name='mac' label='Mac' margin='normal' />
          </Grid>
          <Grid className='grid-item'>
            <TextField
              id='country'
              name='country'
              label='Country'
              margin='normal'
            />
          </Grid>
          <Grid className='grid-item'>
            <TextField
              id='language'
              name='language'
              label='Language'
              margin='normal'
            />
          </Grid>
        </Grid>
        <div className='button-container'>
          <Button type='submit' className='submit-button' variant='contained'>
            Submit
          </Button>
        </div>
      </form>
    </Container>
  );
}
