const axios = require('axios');

export async function getCustomerId(username) {
  const res = await axios.post('/getCustomerIdFromUsername', {
    username: username
  });
  return res.data.customerId;
}

export async function getLoanDetails(bpid) {
  const res = await axios.post('/getDetailsForQuickview', {
    businessPartnerID: bpid
  });
  return res.data;
}

export async function getCouponPdf(request) {
  const res = await axios.post('/getCouponPdf', request, {
    responseType: 'arraybuffer'
  });
  return res.data;
}
