import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Collapse
} from '@material-ui/core';
import '../scss/status-details.scss';
import { useNoteIconMapper } from '../hooks/use-note-icon-mapper';
import PlayArrow from '@material-ui/icons/PlayArrow';

export default function StatusDetails({ expanded, status }) {
  const { securedLoanStatus, agreementStatus, balanceSheetStatus } = status;

  const {
    agreementIcon,
    securedLoanIcon,
    balanceSheetIcon,
    securedLoanNote,
    balanceSheetNote,
    agreementNote
  } = useNoteIconMapper(status);

  return (
    <Collapse in={expanded === 'true'} data-testid='collapse'>
      <div className='status-details'>
        <Typography className='label'>Status Details</Typography>
        <div className='details-table'>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className='status-title'>Balance Sheet</TableCell>
                <TableCell className='status-icon'>
                  {balanceSheetIcon}
                </TableCell>
                <TableCell>
                  <span>{balanceSheetStatus}</span>
                  <br />
                  <div className='status-note'>
                    <PlayArrow className='arrow' />
                    <span>{balanceSheetNote}</span>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className='status-title'>Loan Agreement</TableCell>
                <TableCell className='status-icon'>{agreementIcon}</TableCell>
                <TableCell>
                  <span>{agreementStatus}</span>
                  <br />
                  <div className='status-note'>
                    <PlayArrow className='arrow' />
                    <span>{agreementNote} </span>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className='status-title'>Secured Loan</TableCell>
                <TableCell className='status-icon'>{securedLoanIcon}</TableCell>
                <TableCell>
                  <span> {securedLoanStatus}</span>
                  <br />
                  <div className='status-note'>
                    <PlayArrow className='arrow' />
                    <span>{securedLoanNote}</span>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <span className='contact-info'>
            For questions about your loan status, please call 1(800)248-4030,
            extension 53400
          </span>
        </div>
      </div>
    </Collapse>
  );
}

StatusDetails.propTypes = {
  status: PropTypes.object,
  expanded: PropTypes.string
};
