import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import MainPage from './main-page';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ConnexForm from './connex-form';
import '../scss/app.scss';

const THEME = createMuiTheme({
  typography: {
    fontFamily: 'Gilroy'
  }
});

function App() {
  return (
    <MuiThemeProvider theme={THEME}>
      <div className='app-container'>
        <div className='app-header'>
          <h3>Deferred Payment Loans</h3>
          <img
            className='logo'
            src='https://s7d4.scene7.com/is/image/dpagco/LG%2DPHI%5FFinancial%5FServices%5F173x30?$cropTypeIcon%5Fdesktop$'
            alt='Phi Financial Services Logo'
          />
        </div>

        <Switch>
          <Route exact path='/paymentCoupon/:username' component={MainPage} />
          <Route exact path='/connexForm' component={ConnexForm} />
          <Route component={MainPage} />
        </Switch>
      </div>
    </MuiThemeProvider>
  );
}

export default withRouter(App);
