import { useEffect, useState } from 'react';
import * as DataService from '../services/data-service';
import {
  useGlobalState,
  useGlobalStateDispatch
} from '../context/state-context';

export function useDataService(username) {
  const [customerDetails, setCustomerDetails] = useState({});
  const dispatch = useGlobalStateDispatch();
  const [customerId, setCustomerId] = useState();
  const [isLoading, setLoading] = useState(true);
  const [isRedirect, setRedirect] = useState(false);

  useEffect(() => {
    if (username) {
      fetchCustomerId(username);
    } else {
      process.env.NODE_ENV === 'development'
        ? setRedirect(true)
        : dispatch({
            type: 'setError',
            value: 'Invalid Request'
          });
    }
  }, [username]);

  useEffect(() => {
    customerId &&
      fetchLoanDetails(customerId, setCustomerDetails, dispatch, setLoading);
  }, [customerId]);

  async function fetchCustomerId(username) {
    setLoading(true);
    try {
      const customerId = await DataService.getCustomerId(username);
      setCustomerId(customerId);
    } catch (e) {
      dispatch({
        type: 'setError',
        value: 'There was an error retrieving customer ID'
      });
    } finally {
      setLoading(false);
    }
  }

  async function fetchLoanDetails(customerId) {
    setLoading(true);
    try {
      const customerDetails = await DataService.getLoanDetails(customerId);
      setCustomerDetails(customerDetails);
    } catch (e) {
      dispatch({
        type: 'setError',
        value: 'There was an error retrieving loan data.'
      });
    } finally {
      setLoading(false);
    }
  }

  return {
    customerDetails,
    customerId,
    isLoading,
    isRedirect
  };
}
