import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const ColorCircularProgress = withStyles({
  colorPrimary: {
    color: '#337321'
  }
})(CircularProgress);

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center'
  }
}));

export function ProgressCircle() {
  const classes = useStyles();
  return <ColorCircularProgress className={classes.root} />;
}
