import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import '../scss/print-coupon-button.scss';

export default function PrintCouponButton({ onClick }) {
  return (
    <Button className='print-button' onClick={onClick} variant='contained'>
      Print Coupon
    </Button>
  );
}

PrintCouponButton.propTypes = {
  onClick: PropTypes.func
};
