import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, IconButton, TableBody } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PrintCoupon from './print-coupon';
import StatusDetails from './status-details';
import '../scss/row.scss';

const currencyOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
};

export default function Row({
  details,
  name,
  operation,
  id,
  address,
  setError
}) {
  const [expand, setExpand] = useState(false);
  const balanceDetails = details.deferredPaymentLoanBalanceDetails;
  const hasBalance =
    balanceDetails.interestBalance > 0 || balanceDetails.principalBalance > 0;
  const asterisk = balanceDetails.interestBalance > 0 ? '*' : '';

  const callStatusDetails = () => {
    setExpand(!expand);
  };

  return (
    <TableBody className='status-table'>
      <TableRow key={details.loanID}>
        <TableCell>
          <strong>{details.year}</strong>
        </TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>Deferred Payment Loan</TableCell>
        <TableCell>
          <span>
            {details.status.loanStatus}
            <IconButton onClick={callStatusDetails}>
              <ExpandMoreIcon />
            </IconButton>
          </span>
        </TableCell>
        <TableCell>
          $
          {balanceDetails.principalBalance.toLocaleString(
            undefined,
            currencyOptions
          )}
        </TableCell>
        <TableCell>
          $
          {balanceDetails.interestBalance.toLocaleString(
            undefined,
            currencyOptions
          )}
          {asterisk}
        </TableCell>
        <TableCell>
          {hasBalance && (
            <PrintCoupon
              details={details}
              name={name}
              operation={operation}
              id={id}
              address={address}
              setError={setError}
            />
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={7}>
          <StatusDetails expanded={expand.toString()} status={details.status} />
        </TableCell>
      </TableRow>
    </TableBody>
  );
}

Row.propTypes = {
  details: PropTypes.object,
  name: PropTypes.string,
  operation: PropTypes.string,
  id: PropTypes.string,
  address: PropTypes.object,
  setError: PropTypes.func
};
