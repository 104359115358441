import React from 'react';
import PropTypes from 'prop-types';
import '../scss/error-page.scss';

export default function ErrorPage({ message }) {
  return (
    <span data-testid='error' className='error-message'>
      {message}
    </span>
  );
}

ErrorPage.propTypes = {
  message: PropTypes.string
};
